<template>
  <div>
    <div class="file-upload">
      <div class="file-upload-content" v-if="imageURL">
        <img
            :src="imageURL"
            alt="Cropped Image"
            class="file-upload-image file-upload-images"
            height="190px"
            width="190px"
        />
        <div class="mt-sm-1">
          <CButton
              type="button"
              v-on:click="removeImage"
              size="sm"
              color="danger"
          ><b class="red col-sm-2">{{ $lang.cropper.button.remove }}</b>
          </CButton>
          &nbsp;
          <CButton
              type="button"
              @click.prevent="showFileChooser"
              size="sm"
              color="primary"
          ><b class="red col-sm-2">{{ $lang.cropper.button.upload }}</b>
          </CButton>
        </div>
      </div>
      <div class="file-upload-content" v-else>
        <CButton
            type="button"
            @click.prevent="showFileChooser"
            size="sm"
            color="primary"
        ><b class="red col-sm-2">{{ $lang.cropper.button.upload_profile }}</b>
        </CButton>
      </div>
    </div>
    <CModal
        :title="$lang.cropper.title"
        size="lg"
        :show.sync="ImageCropperModel"
        :no-close-on-backdrop="true"
        color="primary"
    >
      <input
          ref="input"
          type="file"
          name="image"
          accept="image/*"
          @change="setImage"
      />
      <div class="img-cropper">
        <vue-cropper
            dragMode="move"
            :img-style="{ width: '400px', height: '300px' }"
            ref="cropper"
            :src="imgSrc"
            preview=".preview"
            :guides="true"
            :view-mode="2"
            :background="true"
            :rotatable="true"
            :aspectRatio="1"
        />
      </div>
      <div class="actions">
        <a href="#" role="button" @click.prevent="zoom(0.2)">
          {{ $lang.cropper.button.zoom_in }}
        </a>
        <a href="#" role="button" @click.prevent="zoom(-0.2)">
          {{ $lang.cropper.button.zoom_out }}
        </a>
        <a href="#" role="button" @click.prevent="move(-10, 0)">
          {{ $lang.cropper.button.move_left }}
        </a>
        <a href="#" role="button" @click.prevent="move(10, 0)">
          {{ $lang.cropper.button.move_right }}
        </a>
        <a href="#" role="button" @click.prevent="move(0, -10)">
          {{ $lang.cropper.button.move_up }}
        </a>
        <a href="#" role="button" @click.prevent="move(0, 10)">
          {{ $lang.cropper.button.move_down }}
        </a>
        <a ref="flipX" href="#" role="button" @click.prevent="flipX">
          {{ $lang.cropper.button.flip_x }}
        </a>
        <a ref="flipY" href="#" role="button" @click.prevent="flipY">
          {{ $lang.cropper.button.flip_y }}
        </a>
        <a href="#" role="button" @click.prevent="rotate(90)">
          {{ $lang.cropper.button.rotate_90 }}
        </a>
        <a href="#" role="button" @click.prevent="rotate(-90)">
          {{ $lang.cropper.button.rotate_90deg }}
        </a>
        <br/>
        <hr/>
      </div>
      <div>
        <a
            href="#"
            role="button"
            @click.prevent="cropImage"
            class="btn btn-success"
        >
          {{ $lang.cropper.button.save }}
        </a>
        &nbsp; &nbsp;
        <a
            href="#"
            role="button"
            @click.prevent="showFileChooser"
            class="btn btn-warning"
        >
          {{ $lang.cropper.button.upload_image }}
        </a>
      </div>
      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none"
        >Discard
        </CButton
        >
        <CButton
            @click="darkModal = false"
            color="success"
            style="display: none"
        >Accept
        </CButton
        >
      </template>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import {serverBus} from "../main";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);
//  Vue.use(VueTelInput)
export default {
  name: "ImageCropper",
  mixins: [Mixin],
  components: {
    VueCropper
  },
  data() {
    return {
      imgSrc: "",
      ImageCropperModel: false,
      imageURL: localStorage.getItem("userDefaultImage"),
    };
  },
  created() {
    store.commit("showLoader", false); // Loader Off
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;

    serverBus.$on("send_cropper_image_url", function (url) {
      self.imageURL = url;
    });
  },
  methods: {
    check() {
      let self = this;
      self.ImageCropperModel = true;
    },
    cropImage() {
      let self = this;
      self.imageURL = this.$refs.cropper.getCroppedCanvas().toDataURL();
      self.$emit("profileImage", self.imageURL);
      self.ImageCropperModel = false;
    },
    removeImage() {
      let self = this;
      self.imageURL = "";
      self.$emit("profileImage", self.imageURL);
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(e) {
      let self = this;
      self.ImageCropperModel = true;
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}

img {
  max-width: 100%; /* This rule is very important, please do not ignore this! */
}

body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0 auto;
}

input[type="file"] {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;
}

.header h2 {
  margin: 0;
}

.header a {
  text-decoration: none;
  color: black;
}

.content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 614px;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}
</style>
